<template>
  <BasicCard
    :loading="loading"
    class="background_accent"
  >
    <template v-slot:feature>
      <v-chip class="primary ml-2">{{ data.account_type_label }}</v-chip>

      <v-icon right x-large v-if="data.account_type == 'FAX-TO-MAIL'">mdi-fax</v-icon>
      <v-icon right x-large v-else>mdi-phone-voip</v-icon>
    </template>
    <p class="title text--primary mt-1" v-if="data.id">{{ data.id }}</p>


    <v-col
      cols="12"
      v-if="data.account_type == 'HOSTED-EXTENSION' && data.extensionDetails.id == null && data.bill_status != 'C'"
    >
      <v-alert type="error" dense class="mt-2 mb-0"
      >Missing Extension record. Please amend.</v-alert
      >
    </v-col>
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Reference ID</span>
      </v-col>
      <v-col cols="7">
        {{ data.i_account }}
      </v-col>
    </v-row>
    <v-row dense v-if="data.account_type == 'HOSTED-EXTENSION'">
      <v-col cols="5">
        <span class="font-weight-bold">Extension Number</span>
      </v-col>
      <v-col cols="7">
        {{ data.extensionDetails.id }}
      </v-col>
    </v-row>
    <v-row dense v-if="!!data.note">
      <v-col cols="5">
        <span class="font-weight-bold">Description</span>
      </v-col>
      <v-col cols="7">
        {{data.note}}
      </v-col>
    </v-row>
    <v-row dense v-if="!!data.cont1">
      <v-col cols="5">
        <span class="font-weight-bold">Billing Ref</span>
      </v-col>
      <v-col cols="7">
        {{data.cont1}}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Created</span>
      </v-col>
      <v-col cols="7">
        <LocalDateTime :value="data.activation_date" hide-tooltip />
      </v-col>
    </v-row>
    <v-row dense v-if="data.first_usage_time">
      <v-col cols="5">
        <span class="font-weight-bold">First Used</span>
      </v-col>
      <v-col cols="7">
        <LocalDateTime :value="data.first_usage_time" />
      </v-col>
    </v-row>
    <v-row dense v-if="data.last_usage">
      <v-col cols="5">
        <span class="font-weight-bold">Last Used</span>
      </v-col>
      <v-col cols="7">
        <LocalDateTime :value="data.last_usage" relative />
      </v-col>
    </v-row>
    <template v-slot:actions>
      <ButtonWithTooltip
        v-if="cardUsage == 'internal'"
        text="Edit"
        flat
        tooltip="Edit Trunk"
        icon="mdi-pencil"
        @click="showEditDialog = true"
      />
      <ButtonWithTooltip
        text="Delete"
        flat
        tooltip="Terminate Trunk"
        icon="mdi-delete"
        @click="showTerminateDialog = true"
        v-if="data.bill_status !== 'C' && cardUsage == 'internal'"
        color="error"
      />
      <ButtonWithTooltip
        v-if="cardUsage == 'external'"
        text="View"
        flat
        tooltip="view Trunk"
        icon="mdi-eye"
        :to="{
                name: 'TrunkSummary',
                params: {
                    customerId: data.i_customer,
                    trunkId: data.i_account,
                },
            }"
      />
    </template>
    <TerminateTrunkDialog
      v-if="data.i_account != undefined"
      v-model="showTerminateDialog"
      :accountId="data.i_account"
      :accountData="data"
    />
    <EditTrunkDialog v-model="showEditDialog" :data="data"/>
  </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import TerminateTrunkDialog from '../../dialogs/Trunk/TerminateTrunkDialog';
import LocalDateTime from '../../pieces/LocalDateTime';
import EditTrunkDialog from "../../dialogs/Trunk/EditTrunkDialog";
export default {

  name: 'TrunkCard',
  components: {EditTrunkDialog, LocalDateTime, TerminateTrunkDialog, ButtonWithTooltip, BasicCard },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    /*
    There are 2 types here
    'internal' for use on pages for the trunk. Will include edit buttons.
    'external' used in an external source. Show a button to link to the account.
     */
    cardUsage:{
      type: String,
      default:'internal'
    }
  },
  data: () => ({
    showTerminateDialog: false,
    showEditDialog: false,
  }),
};
</script>
