<template>
  <ComplexDialog
    :title="title"
    v-model="visible"
    :max-width="600"
    :loading="busy"

    :step="step"
    :step-end="3"
    :step-next-disabled="nextStepDisabled"
    :step-back-disabled="backStepDisabled"
    @step-next="next"
    @step-back="back"
    @step-end="finish"
    :step-end-text="'Close'"
    :step-next-text="nextLabel"
  >
    <v-window v-model="step" touchless v-if="visible">
      <v-window-item :value="1">
        <h2 class="mb-2">Replacement Emergency Service Address</h2>
        <p v-if="newIpndData == null">No replacement Emergency Service Address selected.</p>
        <div v-if="newIpndData != null" class="mb-4">
          <IPNDDetailsDisplayComponent
            v-if="newIpndData != null"
            :ipnd-data="newIpndData"
          />
        </div>
        <div v-if="currentUniqueIpndOptions != null && Object.keys(currentUniqueIpndOptions).length > 1" class="pb-2">
          <ButtonWithTooltip color="primary" text="Select Existing Emergency Services Address" @click="ipndListDialog = true"/>
        </div>
        <div v-if="currentUniqueIpndOptions != null && Object.keys(currentUniqueIpndOptions).length == 1 && newIpndData != null && newIpndData.hash != Object.keys(currentUniqueIpndOptions)[0]" class="pb-2">
          <ButtonWithTooltip color="primary" :text="'Use \''+currentUniqueIpndOptions[Object.keys(currentUniqueIpndOptions)[0]]['ipnd'].label+'\''" @click="selectIpnd(currentUniqueIpndOptions[Object.keys(currentUniqueIpndOptions)[0]]['ipnd'])"/>
        </div>
        <ButtonWithTooltip color="primary" text="Add Emergency Services Address" @click="ipndAddDialog = true"/>

      </v-window-item>
      <v-window-item :value="2">
        <h1>Switch Emergency Service Address</h1><br />
        <p>Change the Emergency Service Address associated with the DIDs.
          <span v-if="Object.keys(currentUniqueIpndsLinkedToSwtchingDids).length > 1">There is currently multiple Emergency Service Addresses associated with these DIDs.</span>
        </p>

        <h2 v-if="Object.keys(currentUniqueIpndsLinkedToSwtchingDids).length > 0">Current Emergency Service Addresses</h2>
        <h2 v-else>Current Emergency Service Address</h2>
        <br />

        <v-sheet
          v-for="(ipndData, i) in currentUniqueIpndsLinkedToSwtchingDids" :key="i"
          :color="
                        'blue' +
                        ($vuetify.theme.isDark ? ' darken-4' : ' lighten-4')
                    "
          class="pa-4"
          rounded
        >
          <h3 v-if="ipndData.dids.length > 1">DIDs</h3>
          <h3 v-else>DID</h3>
          <ul>
            <li v-for="(item, i) in ipndData.dids"  :key="i">{{item}}</li>
          </ul>
          <IPNDDetailsDisplayComponent
            :ipnd-data="ipndData.ipnd"
          />
        </v-sheet>

        <br /><br />
        <h2 style="margin-bottom:0.6rem;">Replacement Emergency Service Address</h2>
        <p>Any DID(s) selected will have their Emergency Service Address update to the address below.</p>
        <IPNDDetailsDisplayComponent
          :ipnd-data="newIpndData"
        />
      </v-window-item>
      <!--          <v-window-item :value="3">-->
      <!--            <v-row no-gutters>-->
      <!--              <v-col cols="12" class="text-center py-4">-->
      <!--                <v-progress-circular-->
      <!--                  :size="100"-->
      <!--                  :width="7"-->
      <!--                  :color="'primary'"-->
      <!--                  indeterminate-->
      <!--                ></v-progress-circular><br /><br /><h2>Processing request</h2>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </v-window-item>-->
      <v-window-item :value="3">
        <v-row no-gutters v-if="transferResult">
          <v-col cols="12" class="text-center py-4">
            <v-icon size="120" color="success">mdi-check-circle-outline</v-icon>
            <p class="text-h6">Emergency Service Address updated successfully</p>
          </v-col>
        </v-row>
        <v-row no-gutters v-else>
          <v-col cols="12" class="text-center py-4">
            <v-icon size="120" color="error">mdi-close-circle-outline</v-icon>
            <p class="text-h6">Failed to update Emergency Service Address</p>
            <p class="text-h7">Please try again</p>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>

    <IPNDListSelectDialog :ipnd-list="selectableIpndList"
                          v-model="ipndListDialog"
                          @singleSelected="selectIpnd"
    />
    <EditTrunkIPNDAddressDialog
      v-model="ipndAddDialog"
      :porta-account-data="trunkData"
      :return-values="true"
      :current-ipnd-list="selectableIpndList"
      @selected="selectIpnd"
    />

  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import IPNDDetailsDisplayComponent from "../../pieces/IPND/IPNDDetailsDisplayComponent.vue";
import IPNDListSelectDialog from "../IPND/IPNDListSelectDialog.vue";
import EditTrunkIPNDAddressDialog from "../Trunk/EditTrunkIPNDAddressDialog.vue";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip.vue";
export default {
  name: 'DIDSwitchIPNDDID',
  mixins: [apiMixin, dialogMixin],
  components: {
    EditTrunkIPNDAddressDialog,
    IPNDListSelectDialog,
    IPNDDetailsDisplayComponent,
    ComplexDialog,
    ButtonWithTooltip,
  },
  data: () => ({
    step: 1,
    busy: false,
    newIpndData:null,
    currentUniqueIpndsLinkedToSwtchingDids:null,
    currentUniqueIpndOptions:null,
    ipndListDialog: false,
    ipndAddDialog: false,
    transferResult: false,
  }),
  methods: {
    reset: function( ){
      this.step = 1;
      this.newIpndData = null;
      //List of the unique IPND and the DIDs. Used in the summary page
      this.currentUniqueIpndsLinkedToSwtchingDids = null;
      //List of unique IPND options for the user to select
      this.currentUniqueIpndOptions = null;

      this.ipndListDialog = false;
      this.ipndAddDialog = false;
      this.transferResult = false;
      this.processCurrentUniqueIpnds();

      let hashKeys = Object.keys(this.currentUniqueIpndOptions);
      /*
        Note we do not preselect the only IPND.
        As it presents the UI to look like it is replace the IPND with itself.
       */
      // if(hashKeys.length == 1){
      //   this.selectIpnd(this.currentUniqueIpndOptions[hashKeys[0]]['ipnd']);
      // }
    },
    /**
     * We find the unique IPND amoung the DIDs.
     * This is because dids can be passed in and we want to choose an IPND from the DIDs.
     * If this process is used to switch IPND between dids on an account then we also
     * add in the other IPND linked to the trunk.
     * As we want to be able to have the option to switch the IPND so another that is linked to the trunk.
     *
     */
    processCurrentUniqueIpnds: function( ){
      let ret = {};
      this.didList.forEach(function (item) {
        if(!ret[item.ipnd_details.hash]){
          ret[item.ipnd_details.hash]={
            "ipnd":item.ipnd_details,
            "dids":[]
          };
        }
        ret[item.ipnd_details.hash].dids.push(item.number);
      });
      this.currentUniqueIpndsLinkedToSwtchingDids = ret;

      let ret2 = JSON.parse(JSON.stringify(ret));
      if(this.trunkData != null){
        this.selectableIpndList.forEach(function (item) {
          if(!ret2[item.hash]){
            ret2[item.hash]={
              "ipnd":item,
              "dids":[]
            };
          }
        });
      }
      this.currentUniqueIpndOptions = ret2;
    },
    selectIpnd(ipndData){
      this.newIpndData = ipndData;
    },
    next(){
      if(this.step == 1){
        if(this.newIpndData != null){
          this.step = 2;
        }
      }else if(this.step == 2){
        this.update( );
      }
    },
    back( ){
      this.step--;
    },

    finish( ){
      this.visible = false;
    },

    async update( ) {
      this.busy = true;
      let params = {
        'i_did_numbers':[]
      }
      this.didList.forEach(function (item) {
        params.i_did_numbers.push(item.i_did_number);
      });
      params.emergency_service_address_generic_values = this.newIpndData;
      //remove data that is not classed generic values
      delete(params.emergency_service_address_generic_values.check_delete_error);
      delete(params.emergency_service_address_generic_values.update_permission_error);
      delete(params.emergency_service_address_generic_values.ids);
      delete(params.emergency_service_address_generic_values.delete_string);
      if(params.emergency_service_address_generic_values.ids_by_tag){
        delete(params.emergency_service_address_generic_values.ids_by_tag);
      }

      const response = await this.Api.send('put','trunks/'+this.trunkData.i_account+'/emergency-services-addresses/transfer-dids', params);
      this.busy = false;
      if(response.success){
        this.transferResult = true;
        document.dispatchEvent(new CustomEvent('refreshRequested'));
      }else{
        this.transferResult = false;
      }
      this.step = 3;
    },
  },
  watch: {
    // didList: function(value){
    //   this.reset();
    // },
    visible: function( ){
      this.reset( );
    }
  },
  props: {
    title: {
      type: String,
      default: 'Change Emergency Service Address',
    },
    customerId: {
      type: Number,
      default: null,
    },
    trunkData: {
      type: Object,
      default: null,
    },
    didList: {
      default: null,
    },
  },
  computed: {
    isBusy: function(){
      return this.busy;
    },
    trunkId: function ( ){
      if(this.trunkData == null){
        return null;
      }else{
        return this.trunkData.i_account;
      }
    },
    nextStepDisabled(){
      if(this.isBusy){
        return true;
      }
      if(this.step == 1){
        if(this.newIpndData == null){
          return true;
        }
      }else if(this.step == 3){
        return true;
      }
      return false;
    },
    backStepDisabled(){
      if(this.step == 4){
        return true;
      }
      return false;
    },
    nextLabel(){
      if(this.step == 2){
        return 'Submit';
      }else if(this.step == 3){
        return 'Processing';
      }else if(this.step == 4){
        return 'Close';
      }
      return 'Continue'
    },
    selectableIpndList: function( ){
      if(this.trunkData == null){
        return [];
      }

      let ret = [];
      this.trunkData.ipnd_list.forEach(function (item, i) {
        if(!item.is_bc_address && item.vendor_deleted_at == null && !item.pending_clean_up){
          ret.push(item);
        }
      });

      return ret;
    }
  },
};
</script>
