<template>
  <div>
    <v-card v-if="showFilters" class="pa-2 mb-2">
      <v-card-title v-if="title!=null && showFilters">{{title}}</v-card-title>
      <v-row>
        <v-col cols="12" :sm="custId ? 8 : 5">
          <v-text-field
            hide-details
            outlined
            clearable
            dense
            label="Number"
            :disabled="isLoading"
            v-model="localFilter.number"
            @keydown.enter="updateLocalFilter"
            @change="updateDID"
          />
        </v-col>
        <v-col cols="12" sm="3" v-if="!custId">
          <v-select
            hide-details
            outlined
            dense
            label="Status"
            v-model="localFilter.usage"
            :disabled="isLoading"
            :items="usageOptions"
            item-text="option"
            item-value="value"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn
            outlined
            block
            height="40"
            :loading="isLoading"
            @click="updateLocalFilter"
          >Search</v-btn>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn
            outlined
            block
            height="40"
            :loading="isLoading"
            @click="addClick"
            class="primary"
            :dark="!$vuetify.theme.dark"
          ><span classss="hidden-md-and-down" class="hidden-md-only"><i class="v-icon mdi mdi-phone-plus"></i> Add</span>
            <span classss="hidden-lg-and-up" class="d-none d-md-flex d-lg-none d-xl-none"><i class="v-icon mdi mdi-phone-plus"></i></span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <PaginationCard
      :page="filter.page"
      :data="data"
      :limit="filter.limit"
      :loading="loading || isLoading"
      v-on:updateLimit="updateLimit"
      v-on:updatePage="updatePage"
      :no-skeleton="!firstLoad"
    >
      <v-card-title v-if="title!=null && !showFilters">{{title}}</v-card-title>
      <template v-slot:content v-if="$slots.content">
        <slot name="content" />
      </template>

      <v-toolbar
        dense
        fluid
        v-if="selected.length"
        :color="$vuetify.theme.dark ? 'blue-grey' : 'blue lighten-3'"
        flat
      >
        <v-btn icon @click="changeSelectAll(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ selected.length }} DID<span v-if="selected.length > 1">s</span>
          selected</v-toolbar-title>

        <v-spacer></v-spacer>
        <ButtonWithTooltip
          v-if="showTools && trunkData != null"
          color="primary"
          icon="mdi-transfer"
          tooltip="Switch the Emergency Service Address"
          text="Change Emergency Service Address"
          @click="switchIPNDDialog = true"
          :disabled="isLoading"
          padding-right
        />
        <ButtonWithTooltip
          color="primary"
          icon="mdi-transfer"
          tooltip="Transfer DIDs to another trunk or customer"
          text="Transfer"
          @click="transferDIDDialog.show = true"
          :disabled="isLoading"
          padding-right
        />
        <ButtonWithTooltip
          color="error"
          icon="mdi-delete"
          text="Remove"
          tooltip="Remove DIDs from trunk, customer or inventory"
          @click="removeDIDDialog.show = true"
          :disabled="isLoading"
        />
      </v-toolbar>

      <v-simple-table>
        <thead>
        <tr v-show="!selected.length">
          <th v-if="showTools">
            <v-simple-checkbox
              v-if="data.data && data.data.length"
              v-model="selectAll"
              @input="changeSelectAll"
              dense
            />
          </th>
          <th>Number</th>
          <th v-if="!hideStatus">Status</th>
          <th>State</th>
          <th>Emergency Service Contact</th>
          <th>Description / SZU</th>
          <th class="text-center">Periodic Cost</th>
          <th class="text-center"  v-if="$store.state.user.isAdmin">Carrier (admin)</th>
          <th class="text-center"  v-if="$store.state.user.isAdmin">Carrier Status (admin)</th>
        </tr>
        </thead>
        <!--tbody -->

        <v-slide-x-transition tag="tbody" hide-on-leave group>
          <tr v-if="data.data && data.data.length === 0" key="nodata">
            <td colspan="100%" class="text-center">
              No DIDs found
            </td>
          </tr>
          <tr
            v-for="item in data.data"
            :key="item.i_did_number"
            v-bind:class="{
                            'blue-grey darken-2':
                                $vuetify.theme.dark && item.selected,
                            'blue lighten-5': !$vuetify.theme.dark && item.selected,
                        }"
          >
            <td v-if="showTools">
              <v-simple-checkbox
                dense
                v-model="item.selected"
                :disabled="disabled"
                @input="updateSelected"
              />
            </td>
            <td>
              <router-link
                v-if="$store.state.user.isAdmin"
                :to="{name: 'DIDDetail', params: { did: item.hasOwnProperty('number') ? item.number : item.did_number }}"
                style="cursor: pointer"
                class="pa-4"
              ><span class="font-weight-medium">{{item.hasOwnProperty('number') ? item.number : item.did_number}}</span>
              </router-link>
              <span v-else class="font-weight-medium">{{item.hasOwnProperty('number') ? item.number : item.did_number}}</span>
            </td>

            <td v-if="!hideStatus">
              <DIDStatus :data="item" />
            </td>
            <td>{{ item.hasOwnProperty('city_name') ? item.city_name : item.area_name }}</td>
            <td>
              <div v-if="item.ipnd_details != null && !item.ipnd_details.is_bc_address">
                {{item.ipnd_details.label}}: {{item.ipnd_details.address_string}}
              </div>
              <div v-if="item.ipnd_details != null && item.ipnd_details.is_bc_address && item.i_account != null">
                <v-chip small color="red" text-color="white">Missing</v-chip>
              </div>
              <div v-if="item.ipnd_details != null && item.ipnd_details.porta_account_error != null">
                <v-chip small color="red" text-color="white">{{ item.ipnd_details.porta_account_error }}</v-chip>
              </div>
            </td>
            <td>{{ item.description }}</td>
            <td class="text-center">
              <Currency v-if="false" :value="item.hasOwnProperty('periodic_fee') ? item.periodic_fee : item.pricing.customer_costs.periodic" add-gst />
              <Currency :value="parseFloat(item.fee_display)" add-gst />
            </td>
            <td class="text-center" v-if="$store.state.user.user_type === 'admin'">
              <span v-if="item.vendor_tag == 'auswide'">Auswide</span>
              <span v-if="item.vendor_tag == 'net-sip'">NetSIP</span>
              <span v-if="item.vendor_tag == 'vx-group'">VX</span>
            </td>
            <td class="text-center" v-if="$store.state.user.user_type === 'admin'">
              <DIDCarrierStatus small :status="item.carrier_status" />
            </td>
          </tr>
        </v-slide-x-transition>
        <!-- /tbody -->
      </v-simple-table>
      <template v-slot:persistent>
        <MoveDIDDialog
          v-if="showTools"
          v-model="transferDIDDialog.show"
          :customer-id="custId"
          :trunkData="trunkData"
          :did-list="selected"
          @refresh="refresh"
        />
        <RemoveDIDDialog
          v-if="showTools"
          v-model="removeDIDDialog.show"
          :did-list="selected"
          :trunk-data="trunkData"
          @refresh="refresh"
        />
        <DIDSwitchIPNDDID
          v-if="showTools && trunkData != null"
          v-model="switchIPNDDialog"
          :customer-id="custId"
          :trunkData="trunkData"
          :did-list="selected"
        />
      </template>
    </PaginationCard>
  </div>

</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import Currency from '../../pieces/Currency';
import DIDStatus from '../../pieces/DID/DIDStatus';
import DIDCarrierStatus from '../../pieces/DID/DIDCarrierStatus';
import RemoveDIDDialog from '../../dialogs/DID/RemoveDIDDialog';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import MoveDIDDialog from '../../dialogs/DID/MoveDIDDialog';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import DIDSwitchIPNDDID from "../../dialogs/DID/DIDSwitchIPNDDialog.vue";
export default {
  name: 'DIDListCard',
  mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
  data: () => ({
    localFilter: {
      number: '',
      usage: 'A',
    },
    usageOptions: [
      { option: 'All', value: 'A' },
      { option: 'Unused', value: 'I' },
      { option: 'Unused+Res', value: 'F' },
      { option: 'In Use', value: 'U' },
      { option: 'Frozen', value: 'Z' },
    ],
    selectAll: false,
    transferDIDDialog: {
      show: false,
    },
    removeDIDDialog: {
      show: false,
    },
    switchIPNDDialog: false,
    selected: [],
    table: {
      headers: [
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'number',
        },
        { text: 'City Name', value: 'city_name' },
        { text: 'Description', value: 'description' },
      ],
    },
  }),
  components: {
    DIDSwitchIPNDDID,
    MoveDIDDialog,
    ButtonWithTooltip,
    RemoveDIDDialog,
    DIDCarrierStatus,
    DIDStatus,
    Currency,
    PaginationCard,
  },
  props: {
    title:{
      type: String,
      default: null,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    trunkData: {
      type: Object,
      default: null,
    },
    custId: {
      type: Number,
      default: null,
    },
    reload: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideStatus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showTools: {
      type: Boolean,
      default: false,
    },
    addFunction: Function,
  },
  methods: {
    refresh() {
      document.dispatchEvent(new CustomEvent('refreshRequested'));
    },
    async update() {
      this.isLoading = true;
      this.getLocalFilters();
      let params = { limit: this.filter.limit, page: this.filter.page };
      if (this.custId > 0) params.i_customer = this.custId;
      if (this.acctId > 0) params.i_account = this.acctId;
      if (this.filter.frozen) params.frozen = this.filter.frozen;
      if (this.filter.usage) params.usage = this.filter.usage;
      if (this.filter.number) params.number = this.filter.number;
      if (this.filter.ipndId) params.ipnd_id = this.filter.ipndId;
      if (this.filter.vendorNumber) params.vn = this.filter.vendorNumber;

      this.Api.setHttpObject({timeout:60000});

      const response = await this.Api.send('post','dids/list', params);

      this.Api.setHttpObject({timeout:this.Api.DEFAULT_TIMEOUT});

      this.selected = [];
      this.selectAll = false;
      this.isLoading = false;
      this.firstLoad = false;
      if (response.success) {
        this.data = response.data;
      } else {
        this.data = [];
      }
    },
    updateStatus(data) {
      this.$emit('update', { status: data, page: 1 })
    },
    updateSelected() {
      this.selected = this.data.data.filter((x) => x.selected);
    },
    changeSelectAll(value) {
      this.data.data.forEach((item) => {
        item.selected = value;
      });
      this.selectAll = value;
      this.updateSelected();
    },
    updateDID() {
      if (!!this.localFilter.number) {
        if (this.localFilter.number.includes(' ')) this.localFilter.number = this.localFilter.number.replaceAll(' ', '');
      }
    },
    addClick() {
      if (this.addFunction) {
        this.addFunction();
      } else {
        console.log('No function provided!');
      }
    }
  },

  mounted() {
    // console.log('Type of parentFunction on mounted:', typeof this.parentFunction);
  },
  computed:{
    acctId: function( ){
      if(this.trunkData == null){
        return null;
      }else{
        return this.trunkData.i_account;
      }
    }
  }
};
</script>
