<template>
  <v-row dense>
    <v-col cols="12">
      <v-card class="pa-2 mb-2">
        <v-row>
          <v-col cols="12" sm="2">
            <v-text-field
              hide-details
              outlined
              clearable
              dense
              label="Customer ID"
              :disabled="isBusy"
              v-model="searchFilters.customer_id"
              @keydown.enter="updateSearch"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              hide-details
              outlined
              clearable
              dense
              label="Auth ID"
              :disabled="isBusy"
              v-model="searchFilters.id"
              @keydown.enter="updateSearch"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              hide-details
              outlined
              clearable
              dense
              label="Description"
              :disabled="isBusy"
              v-model="searchFilters.note"
              @keydown.enter="updateSearch"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              hide-details
              outlined
              dense
              label="Status"
              v-model="searchFilters.active"
              :disabled="isBusy"
              :items="statusOptions"
              item-text="option"
              item-value="value"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn
              outlined
              block
              height="40"
              :loading="isBusy"
              @click="updateSearch"
            >Search</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-2 mb-2" v-if="responseResults.length > 0">
        <v-row>
          <v-col cols="12">
            <p style="font-size:smaller;" class="mb-0">
              Here is a list of Accounts/Trunks that require more details to fulfil their Emergency Service Address (IPND) requirements.
              Details can be adjusted on the Account/Trunk summary page.
            </p>
          </v-col>
        </v-row>
      </v-card>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="responseResults"
            :items-per-page="10"
            class="elevation-1"
            item-key="i_account"
          >
            <template v-slot:[`item.id`]="{ item }">
              <router-link
                :to="{
                              name: 'TrunkSummary',
                              params: {
                                  customerId: item.i_customer,
                                  trunkId: item.i_account,
                              },
                          }"
                class="font-weight-medium"
              >{{ item.id }}</router-link>
            </template>
            <template v-slot:[`item.i_customer`]="{ item }">
              <router-link
                :to="{
                            name: 'CustomerSummary',
                            params: {
                                customerId: item.i_customer,
                            },
                        }"
              >{{ item.customer_name || item.i_customer }}</router-link>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <TrunkStatus :data="item" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import partnerDataMixin from '../../mixins/partnerDataMixin';
import TrunkStatus from "../../components/pieces/Trunk/TrunkStatus.vue";
export default {
  name: 'PartnerIpndOnboarding',
  components: {
    TrunkStatus,
  },
  mixins: [ partnerDataMixin],
  data: () => ({
    searchFilters: {
      page:1,
      limit: 10,
      status: null,
      name: null,
      accountType: null,
      customer_id:null,
    },
    busy: false,
    listData: {
      total:0,
      pages:0,
      results:[],
    },
    responseResults:[],
    statusOptions: [
      { option: 'Active', value: 'Y' },
      { option: 'Terminated', value: 'N' },
    ],
    headers: [
      {
        text: 'Auth ID',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Description',
        sortable: true,
        value: 'note',
      },
      {
        text: 'Customer',
        sortable: true,
        value: 'i_customer',
      },
      {
        text: 'Product',
        sortable: true,
        value: 'product_visible_name',
      },
      {
        text: 'Status',
        sortable: true,
        value: 'status',
      },
    ],

  }),
  beforeRouteLeave(to, from, next){
    return (this.showNewCustomer) ? next(false) : next();
  },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
  },
  methods: {
    updateSearch(data){
      this.searchFilters.page = 1;
      this.update( );
    },
    async update() {
      this.busy = true;
      let params = this.searchFilters;

      for (const [key, value] of Object.entries(params)) {
        if(value == null || value == undefined){
          delete params[key];
        }
      }

      let queryString = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      }).join('&');

      const response = await this.Api.send('get','partners/'+this.partnerId+'/ipnd-onboarding?'+queryString);

      this.busy = false;
      if (response.success) {
        this.responseResults = response.data;
      } else {
        this.responseResults = [];
      }
    },
  },
  beforeMount() {
    this.searchFilters.customer_id = this.$route.query.customer_id;
  },
  mounted() {
    this.updateSearch( );
  }

};
</script>
